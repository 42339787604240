










































import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { CustomDataListAssetAdditions } from "@/models/interface/assets.interface";
import { currencyFormat } from "@/validator/globalvalidator";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";
const Props = Vue.extend({
  props: ["loadingPostingAsset", "responseListPostingAsset"],
});
@Component({
  watch: {
    responseListPostingAsset: {
      immediate: true,
      deep: true,
      handler(value) {
        if (Object.keys(value).length > 0) {
          this.totalElements = value.totalElements;
          let newData: CustomDataListAssetAdditions[] = [];
          value.data.forEach((data, index) => {
            newData = [
              ...newData,
              {
                ...data,
                customAssetCategory: null,
                key: index,
                location: null,
                customAssetCost: null,
                acquisitionDate: data.acquisitionDate
                  ? moment(data.acquisitionDate).format(DEFAULT_DATE_FORMAT)
                  : "-",
                parseAssetCategory: data.assetCategory?.id
                  .split(".")
                  .map(dataMapString => {
                    return dataMapString.replace(/\b[a-z](?=[a-z]{2})/g, e =>
                      e.toUpperCase()
                    );
                  })
                  .join("."),
              },
            ];
            // parse data category
            if (data.assetCategory) {
              let tempSegment = this.loopDataAssetCategory(data.assetCategory);
              newData[
                index
              ].customAssetCategory = `${tempSegment.first}.${tempSegment.second}`;
            }
            // parse data location
            newData[index].location = data.assetLocation
              ? `${data.assetLocation.warehouse.branchWarehouse.name} - ${data.assetLocation.warehouse.name} - ${data.assetLocation.name}`
              : null;

            // custom assetcost to IDR
            newData[index].customAssetCost = currencyFormat(data.assetCost);
          });
          this.dataSource = newData;
        }
      },
    },
  },
})
export default class ListPostingAsset extends Props {
  columnsTable = [
    {
      title: this.$t("lbl_book"),
      dataIndex: "assetBook",
      key: "assetBook",
      width: "150px",
    },
    {
      title: this.$t("lbl_category"),
      dataIndex: "parseAssetCategory",
      key: "parseAssetCategory",
      scopedSlots: { customRender: "isNull" },
      width: "150px",
    },
    {
      title: this.$t("lbl_description"),
      dataIndex: "description",
      key: "description",
      scopedSlots: { customRender: "isNull" },
      width: "220px",
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      key: "unitCode",
      scopedSlots: { customRender: "isNull" },
      width: "220px",
    },
    {
      title: this.$t("lbl_location"),
      dataIndex: "location",
      key: "location",
      scopedSlots: { customRender: "isNull" },
      width: "220px",
    },
    {
      title: this.$t("lbl_qty"),
      dataIndex: "unit",
      key: "unit",
      scopedSlots: { customRender: "isCurrency" },
      width: "150px",
    },
    {
      title: this.$t("lbl_life_months"),
      dataIndex: "lifeMonths",
      key: "lifeMonths",
      scopedSlots: { customRender: "isNull" },
      width: "150px",
    },
    {
      title: this.$t("lbl_acquisition_date"),
      dataIndex: "acquisitionDate",
      key: "acquisitionDate",
      scopedSlots: { customRender: "isNull" },
      width: "220px",
    },
    {
      title: this.$t("lbl_asset_cost"),
      dataIndex: "customAssetCost",
      key: "customAssetCost",
      scopedSlots: { customRender: "isNull" },
      width: "150px",
    },
    {
      title: this.$t("lbl_source"),
      dataIndex: "source",
      key: "source",
      scopedSlots: { customRender: "isNull" },
      width: "150px",
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      key: "serialNumber",
      scopedSlots: { customRender: "isNull" },
      width: "150px",
    },
    {
      title: this.$t("lbl_receive_number"),
      dataIndex: "receiveNumber",
      key: "receiveNumber",
      scopedSlots: { customRender: "isNull" },
      width: "160px",
    },
    {
      title: this.$t("lbl_journal_number"),
      dataIndex: "journalNo",
      key: "journalNo",
      width: "150px",
      scopedSlots: { customRender: "clickColumn" },
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "state",
      key: "state",
      scopedSlots: { customRender: "isNull" },
      width: "170px",
    },
  ];
  totalElements = 0;
  limit = 10;
  page = 1;
  dataSource = [];

  loopDataAssetCategory(dataAssetCategory): { first: string; second: string } {
    let tempSegment = {
      first: "",
      second: "",
    };
    dataAssetCategory.segments.forEach((dataCategory, indexCategory) => {
      if (dataCategory.first) {
        tempSegment.first += dataCategory.first;
      }
      if (dataCategory.second) {
        tempSegment.second += dataCategory.second;
      }
      if (indexCategory % 2 === 0) {
        tempSegment.first += ".";
      }
    });
    return tempSegment;
  }

  handleFindData() {
    this.$emit("handle-visible", true);
  }

  responsePageSizeChange(response: ResponsePagination): void {
    this.limit = response.size;
    this.page = 1;
    this.$emit("handle-pagination", { page: this.page, limit: this.limit });
  }

  responseCurrentPageChange(response: ResponsePagination): void {
    this.page = response.page;
    this.$emit("handle-pagination", { page: this.page, limit: this.limit });
  }

  handleClickColumn(record, objColumnNameValue: { column; value }): void {
    if (objColumnNameValue.column === "journalNo") {
      this.$router.push("/generaljournal/journal/detail/" + record.journalId);
    }
  }
}
